import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function Listitem() {
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.4)' }}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
        </ListItemAvatar>
        <ListItemText primary="Photos" 
        
        secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
              >
                Ali Connors
              </Typography>
              {" — I'll be in your neighborhood doing errands this…"}
            </React.Fragment>
          }        

        />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
        <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
        </ListItemAvatar>
        <ListItemText primary="Photos" 

secondary={
    <React.Fragment>
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.primary', display: 'inline' }}
      >
        Ali Connors
      </Typography>
      {" — I'll be in your neighborhood doing errands this…"}
    </React.Fragment>
  } 
        
        />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
        <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
        </ListItemAvatar>
        <ListItemText primary="Photos" 

secondary={
    <React.Fragment>
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.primary', display: 'inline' }}
      >
        Ali Connors
      </Typography>
      {" — I'll be in your neighborhood doing errands this…"}
    </React.Fragment>
  } 
        
        />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
        <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg"/>
        </ListItemAvatar>
        <ListItemText primary="Photos" 

secondary={
    <React.Fragment>
      <Typography
        component="span"
        variant="body2"
        sx={{ color: 'text.primary', display: 'inline' }}
      >
        Ali Connors
      </Typography>
      {" — I'll be in your neighborhood doing errands this…"}
    </React.Fragment>
  } 
        
        />
        </ListItemButton>
      </List>
      <Stack spacing={2} sx={{justifyContent:'center', alignItems:'center'}}>
      <Pagination count={5} variant="outlined" color="primary" size="small"/>
    </Stack>
    </Box>
    
  );
}