import React from 'react';
import { useState, useEffect } from 'react';
import './App.css';
import AppBar from './Components/Appbar';
import Listitem from './Components/Listitem';
import Datepicker from './Components/Datepicker';
import Notify from './Components/Notify';
import Footer from './Components/Footer';

function App() {

  return (
    <div className="App">
      <AppBar /> {/* Pass the handler to AppBar */}
      <div className="wrapper">
        <div className="section" id="list-section"><Listitem /></div>
        <div className="section" id="date-section"><Datepicker /></div>
        <div className="section" id="notify-section"><Notify /></div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
